<template>
  <article class="tournament" :class="classes" data-t="tournament">
    <header class="header">
      <div class="header-top">
        <h3 class="title">{{ card.name }}</h3>
        <div class="badges">
          <StBadge
            :background="props.isMobile ? 'ghost' : 'gray'"
            :icon-color="props.isMobile ? 'white' : 'gray'"
            type="timer"
            :label="isProcessed ? t('tournament.ended') : countDown"
          />
          <StBadge
            :background="props.isMobile ? 'ghost' : 'gray'"
            :icon-color="props.isMobile ? 'white' : 'gray'"
            type="count"
            :label="card.prizePlaceNumber"
          />
        </div>
      </div>
      <div class="header-bottom">
        <p>{{ t('tournament.prizePool') }}</p>
        <PrizeAmount
          :amount="formattedAmount"
          :currency-icon="currencyIcon"
          :is-rise-up="isRiseUp"
          :size="props.isMobile ? 'ml' : 'm'"
        />
      </div>
    </header>
    <div class="leader-board-wrapper">
      <LeaderBoard :card="card" :size="props.isMobile ? 's' : 'm'" />

      <footer>
        <WideButton
          v-if="!isProcessed && !isCalculated"
          :players-count="card.membersNumber"
          :tournament-id="card.id"
        />
        <template v-if="isProcessed">
          <StButton v-if="isBonusCalmVisible" to="/bonuses" block>
            {{ t('tournament.getPrize', [userAmountPrize]) }}
            <StBadge type="timer" :label="expireBonusTimer" />
          </StButton>
          <StButton v-else :to="`/tournaments/${card.id}`" block type="gray">
            {{ t('tournament.more') }}
          </StButton>
        </template>
      </footer>
    </div>
  </article>
</template>

<script setup lang="ts">
import type { Tournament } from '../../types'
import { useTournament } from '../../composables/useTournament'
import LeaderBoard from './parts/LeaderBoard.vue'
import PrizeAmount from '../PrizeAmount/PrizeAmount.vue'
import WideButton from './parts/WideButton.vue'

const props = defineProps<{
  card: Tournament
  isMobile?: boolean
}>()

const { t } = useI18n()

const { card } = toRefs(props)
const {
  isProcessed,
  isCalculated,
  formattedAmount,
  currencyIcon,
  isRiseUp,
  isUserInWinners,
  currentUserPlace,
  isBonusClaimedByUser,
} = useTournament(card)
const backgroundPath = computed(() =>
  card.value.previewBannerUrl ? `url("${card.value.previewBannerUrl}"` : 'none',
)

const { startCountDown, countDown } = useCountDown(card.value.validDateTo)
startCountDown()

const { startCountDown: startExpireBonusTimer, countDown: expireBonusTimer } =
  useCountDown(currentUserPlace.value?.bonusExpiredAt ?? 0)
startExpireBonusTimer()

const { format } = useCryptoFormatter()
const userAmountPrize = computed(() =>
  format(currentUserPlace.value?.amountOfPrize ?? 0),
)

const classes = computed(() => ({
  processed: isProcessed.value,
  mobile: props.isMobile,
}))

const isBonusCalmVisible = computed(
  () =>
    isUserInWinners.value &&
    !isBonusClaimedByUser.value &&
    expireBonusTimer.value,
)
</script>

<style scoped>
.header {
  position: relative;
  height: 184px;
  padding: var(--spacing-200);
  border-radius: var(--border-radius-150);

  &::after {
    content: '';

    position: absolute;
    z-index: -1;
    inset: 0;

    background-image: v-bind(backgroundPath);
    background-repeat: no-repeat;
    background-position: center right;
    background-size: cover;
    border-radius: var(--border-radius-150);
  }
}

.header-top {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-075);
}

.badges {
  display: flex;
  gap: var(--spacing-075);
  align-items: center;
}

.title {
  overflow: hidden;

  margin: 0;

  font: var(--desktop-text-lg-semibold);
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.header-bottom {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-100);
  margin-top: var(--spacing-300);

  p {
    margin: 0;
    font: var(--desktop-text-xs-semibold);
    color: var(--text-tertiary);
    text-align: left;
  }
}

.leader-board-wrapper {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-050);
}

.tournament {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-050);

  min-width: 0;

  box-shadow: var(--shadows-shadow-xs);

  &.processed {
    .header {
      &::after {
        filter: grayscale(1);
      }
    }
  }

  &.mobile {
    gap: 0;

    .header {
      z-index: 1;
      height: 160px;
      box-shadow: var(--shadows-shadow-xs);
    }

    .header-top {
      flex-direction: row;
      justify-content: space-between;
    }

    .title {
      font: var(--mobile-title-2-semibold);
    }

    .badges {
      gap: var(--spacing-100);
    }

    .header-bottom {
      gap: 3px; /* stylelint-disable-line */
      margin-top: 44px; /* stylelint-disable-line */

      p {
        font: var(--mobile-caption-1-medium);
      }
    }

    .leader-board-wrapper {
      gap: var(--spacing-075);

      margin-top: calc(var(--spacing-200) * -1);
      padding: var(--spacing-200);
      padding-top: var(--spacing-300);

      background: var(--background-primary);
      border-radius: var(--border-radius-150);
    }
  }
}
</style>
